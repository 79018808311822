define("@fleetbase/fleetops-data/utils/should-not-load-relation", ["exports", "@ember/utils", "@ember/string"], function (_exports, _utils, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = shouldNotLoadRelation;
  function shouldNotLoadRelation(model, relationship, relationshipId = null) {
    relationshipId = relationshipId === null ? `${(0, _string.underscore)(relationship)}_uuid` : relationshipId;
    return (0, _utils.isBlank)(model[relationshipId]) || !(0, _utils.isBlank)(model[relationship]);
  }
});