define("@fleetbase/fleetops-data/utils/relation-is-loaded", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = relationIsLoaded;
  function relationIsLoaded(model, relationship) {
    return !(0, _utils.isBlank)(model[relationship]);
  }
});